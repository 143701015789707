<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card w-100 bg-lightblue p-lg-5 p-4 border-0 rounded-lg d-block float-left">
                    <img :src="user.photo ? user.photo : '/images/profile.png'" alt="icon" class="sm-mt-2 w75 position-relative top--10 float-left mr-2 mt--1 ">
                    <h2 class="display1-size display2-md-size d-inline-block float-left mb-0 text-grey-900 fw-700"><span class="font-xssss fw-600 text-grey-500 d-block mb-2 ml-1">Welcome back</span> Hi, {{user.name}} </h2>
                    <!-- <img src="https://via.placeholder.com/250x150.png" alt="icon" class="w250 right-15 top-0 position-absolute d-none d-xl-block"> -->
                </div>
            </div>            
        </div> 
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "Home",
        data(){
            return {
                media : process.env.VUE_APP_URL_CLOUD,
                user: {
                    name: 'Loading....',
                    email: 'Loading....',
                    photo: ''
                }
            }
        },
        mounted() {
            axios.get(`${process.env.VUE_APP_URL_API}/user`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.user.name = res.data.name
                this.user.email = res.data.email
            }).catch((err) => console.error(err))
        },
    }
</script>
